<template>
  <div id="branches">
    <div style="margin-bottom: 50px">
      <b-card no-body>
        <b-card-header class="pb-50">
          <b-row no-gutters class="w-100">
            <b-col>
              <h5>{{ msg("Filters") }}</h5>
            </b-col>
            <b-col>
              <div class="d-flex justify-content-end">
                <b-link style="color: inherit">
                  <feather-icon icon="RefreshCcwIcon" size="16"/>
                </b-link>
              </div>
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body>
          <b-row>
            <!--Id-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("ID") }}</label>
              <b-form-input
                  v-model="filter.id"
                  class="d-inline-block mr-1"
              />
            </b-col>

            <!-- First name-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("Name") }}</label>
              <b-form-input
                  v-model="filter.name"
                  class="d-inline-block mr-1"
              />
            </b-col>
            <!-- Last name-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("Currency") }}</label>
              <b-form-input
                  v-model="filter.cur"
                  class="d-inline-block mr-1"
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-card no-body>
        <!--Card Content-->
        <div v-if="loaded">
          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                <label>{{ msg('Show') }}</label>
                <v-select
                    v-model="amountPerPage"
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    :options="[10, 25, 50, 100]"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                />
                <label>{{ msg('entries') }}</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input v-model="filter.search" class="d-inline-block mr-1" :placeholder="msg('Search')"/>
                  <b-button variant="primary" @click="onBusinessUnitSelect(0)">
                    <span class="text-nowrap">{{ msg('New Branch') }}</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          <!--Table-->
          <table class="table b-table table-hover" v-bind:class="{'table-striped':isLoaded}">
            <thead>
            <tr>
              <!--Table header-->

              <th @click="sort('id')" aria-sort="none"><a>{{ msg('Id') }}</a></th>
              <th @click="sort('name')" aria-sort="none">{{ msg('Name') }}</th>
              <th @click="sort('name')" aria-sort="none">{{ msg('Address') }}</th>
              <th @click="sort('mailid')" aria-sort="none">{{ msg('Mail') }}</th>
              <th @click="sort('cur')" aria-sort="none">{{ msg('Currency') }}</th>
              <!--Options in header-->

            </tr>
            </thead>
            <tbody>
            <!--Table-->
            <template v-if="isLoaded">
              <tr v-for="branch in allBranches" v-bind:key="branch.id">
                <td @click="onBusinessUnitSelect(branch.id);">
                  {{ branch.id }}
                </td>
                <td @click="onBusinessUnitSelect(branch.id)">
                  {{ branch.name }}
                </td>
                <td @click="onBusinessUnitSelect(branch.id)">
                  {{ branch.address }}
                </td>

                <td @click="onBusinessUnitSelect(branch.id);">
                  {{ branch.mail }}
                </td>
                <td @click="onBusinessUnitSelect(branch.id);">
                  {{ branch.cur }}
                </td>
              </tr>
            </template>
            <!--Loading-->
            <template v-else>
              <tr class="table-loading">
                <td colspan="9">
                  <p>
                    <b-spinner label="Spinning"/>
                    {{ msg('loading') }}
                  </p>
                </td>
              </tr>
            </template>
            </tbody>
          </table>

          <!--Components-->
          <!--Edit branch Modal -->
          <b-modal no-fade v-model="dialogs.branch" size="xl" ref="branch-modal" id="branch-modal" :hide-footer="true"
                   :hide-header="false" no-close-on-backdrop centered>
            <branch-panel ref="businessUnitPanel" :id="selected" @submitted="onSubmitted"/>
            <div class="text-center" style="justify-content:center">
              <b-button variant="outline-primary" class="btn-dialog" @click="submitBusinessUnit"
                        :disabled="saving === false">
                {{ msg("Save") }}
              </b-button>
            </div>
          </b-modal>
        </div>
        <div class="card-body" v-else>
          <div style="min-height: 300px;text-align: center">
            <div style="margin-top: 20%">
              <b-spinner label="Spinning"/>
              {{ msg('loading') }}
            </div>
          </div>

        </div>
      </b-card>
    </div>

    <!--     <div class="p-1 w-100">
             <branches-settings/>
         </div>
 -->
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import BranchPanel from "../../components/panels/branches/branchPanel";
import BranchesSettings from "./BranchesSettings";
import PageTitle from "@/@core/components/page-title/PageTitle";


export default {
  name: "branches",
  // components: {BranchPanel, BranchesSettings, PageTitle},
  components: {BranchPanel},

  data() {
    return {
      amountPerPage: 10,
      selected: 0,
      loaded: false,
      saving: true,
      error: '',
      filter: {search: ""},
      tableSort: {},
      //Dialogs to show
      dialogs: {branch: false}
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.refresh({
          amountPerPage: 5,
          filter: this.filter
        })
      }
    }
  },
  computed: {
    ...mapGetters('translator', ['msg']),
    ...mapGetters('data', ['getGroupById']),
    ...mapGetters('branches', ['allBranches']),
    ...mapGetters('clients', ['groupAlias', 'branchAlias']),

    ...mapGetters('data', {allGroups: 'getGroups'}),
    ...mapGetters('branches', {
      _isLoaded: "isLoaded", dataError: "getError",
      getMail: "getMailById", allMails: "getMails",
      getKyc: "getKycById", allKyc: "getKyc",
      getOrganization: "getOrganizationById", allOrganizations: "getOrganization"
    }),
    ...mapGetters('data', {_getCountry: 'getCountry'}),
    isLoaded() {
      return this._isLoaded;
    }
  },
  created() {
    let $this = this;
    this.loadData().then(() => {
      this.loaded = true;
    }, (error) => $this.error = error);
  },
  methods: {
    ...mapActions('branches', ['loadData', 'change', 'refresh']),
    ...mapGetters('users', {_loadUserData: 'loadData'}),
    ...mapActions('branches', {_refresh: 'refresh'}),
    ...mapActions('branches', {_reload: 'reload'}),
    refresh() {
      this._refresh({amountPerPage: 10, filter: this.filter})
    },
    reload() {
      this._reload();
      this.refresh();
    },
    onBusinessUnitSelect(id) {
      this.selected = id;
      this.dialogs.branch = true
    },
    filterUpdate() {
      this.selectedRows = [];
      this.refresh()

    },
    sort(col) {
      if (this.tableSort.sortcolumn !== col) {
        this.tableSort.sortby = 'asc';
        this.tableSort.sortcolumn = col;
        return;
      }
      this.tableSort.sortby = this.tableSort.sortby === 'asc' ? 'desc' : 'asc';
    },
    sortStatus(col) {
      if (this.tableSort.sortcolumn !== col)
        return "none";
      return this.tableSort.sortby === 'asc' ? 'ascending' : 'descending'
    },
    submitBusinessUnit() {
      let $this = this;
      this.$root.$emit('crm::submitBusinessUnit')
      // setTimeout(() => $this.refresh(), 800)
      this._loadUserData();
    },
    onSubmitted(server) {
      console.log("Business unit -> onSubmitted()", server)
      if (server.id != null)
        this.selected = server.id;
      this.refresh()
      window.location.reload();
    }
  }
}
</script>

<style>

</style>
