<template>
  <div class="businessunitPanel">
    <b-tabs class="tabs-title">
      <b-tab :title="branchAlias" active>
        <div class="mt-2" v-if="loaded">

          <validation-observer ref="refFormObserver">
            <!-- Header: Personal Info -->
            <div class="d-flex">
              <feather-icon icon="UserIcon" size="19"/>
              <h4 class="mb-0 ml-50">
                {{ msg('Unit') }}
              </h4>
            </div>


            <!-- Form: Personal Info Form -->
            <b-row class="mt-1">
              <!-- Field: First name -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Name')" label-for="businessunit-name">
                  <validation-provider #default="{ errors }" :name="msg('Name')" rules="required">
                    <b-form-input id="businessunit-name" v-model="businessunit.name" :state="errors.length > 0 ? false:null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field: First name -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Contact fullname')" label-for="businessunit-contactname">
                  <validation-provider #default="{ errors }" :name="msg('Contact fullname')" rules="required">
                    <b-form-input id="businessunit-contactname" v-model="businessunit.contact" :state="errors.length > 0 ? false:null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field: First name -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Contact address')" label-for="businessunit-add">
                  <validation-provider #default="{ errors }" :name="msg('Contact address')" rules="required">
                    <b-form-input id="businessunit-add" v-model="businessunit.address" :state="errors.length > 0 ? false:null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field: First name -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Contact phone')" label-for="businessunit-phone">
                  <validation-provider #default="{ errors }" :name="msg('Contact phone')" rules="required">
                    <b-form-input id="businessunit-phone" v-model="businessunit.phone" :state="errors.length > 0 ? false:null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

 <!-- Field: First name -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Contact email')" label-for="businessunit-email">
                  <validation-provider #default="{ errors }" :name="msg('Contact email')" rules="required">
                    <b-form-input id="businessunit-email" v-model="businessunit.email" :state="errors.length > 0 ? false:null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>


              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Currency')" label-for="businessunit-currency">
                  <v-select
                      :dir="isRTL ? 'rtl' : 'ltr'"
                      v-model="businessunit.cur"
                      :options="[{value: 'USD',label:msg('DOLLAR')},{value: 'EUR',label:msg('EURO')},{value: 'ILS',label:msg('SHEKEL')},{value: 'GBP',label:msg('POUND')}]"
                      class="w-100"
                      :reduce="(type) => type.value"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: company clients -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="groupAlias" label-for="businessunit-group">
                  <v-select
                      :dir="isRTL ? 'rtl' : 'ltr'"
                      v-model="businessunit.group"
                      :options="allGroups"
                      class="w-100"
                      label="name"
                      :reduce="(group) => group.id"
                  />
                </b-form-group>
              </b-col>


              <!-- Field: Document number -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Website')" label-for="businessunit-website">
                  <b-form-input id="businessunit-website" v-model="businessunit.website"/>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('External Payment Link')" label-for="businessunit-externalLink">
                  <b-form-input id="businessunit-externalLink" v-model="businessunit.externalLink"/>
                </b-form-group>
              </b-col>


              <!-- Field: Document number -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Hostaway ID')" label-for="businessunit-website">
                  <b-form-input v-model="businessunit.hostawayId"/>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Hostaway SECRET')" label-for="businessunit-externalLink">
                  <b-form-input v-model="businessunit.hostawaySecret"/>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Management fee (in %)')" label-for="businessunit-externalLink">
                  <b-form-input  type="number" step="0.5" v-model="businessunit.managementFee"/>
                </b-form-group>
              </b-col>


            </b-row>

          </validation-observer>
        </div>
        <div v-else-if="this.error != null">
          <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
            <strong>{{ error }}</strong>
          </b-alert>
        </div>
        <div v-else>
          <div style="min-height: 300px;text-align: center">
            <div style="margin-top: 20%">
              <b-spinner label="Spinning"/>
              {{ msg('loading') }}
            </div>
          </div>
        </div>
      </b-tab>

      <b-tab title="Opening times">
        <perfect-scrollbar style="max-height: 550px">
          <!-- default -->
          <branch-time v-model="businessunit.openingTime"></branch-time>
        </perfect-scrollbar>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'
import minLength from 'vuelidate/src/validators/minLength'
import BranchTime from '@/components/panels/branches/BranchTime'

export default {
  name: 'branchPanel',
  components: { BranchTime: BranchTime },
  data() {
    return {
      openingTime: {},
      loaded: false,
      error: null,
      businessunit: {
        cur: 'EUR',
        id: 0,
        group: null,
        kycid: 0,
        mailid: 0,
        name: '',
        orgid: 1,
        companyClients: 0,
        externalLink: '',
        website: '',
        openingTime: {}
      },

      //Listeners
      listeners: {
        submit: () => {
        }
      }
    }
  },
  props: {
    id: Number,
  },
  watch: {
    id: function () { // watch it
      this.onLoad()
      this.error = null
    }
  },
  computed: {
    ...mapGetters('translator', ['msg']),
    ...mapGetters('branches', ['getGroups']),
    ...mapGetters('clients', ['groupAlias', 'branchAlias']),
    canSubmit: {
      get() {
        return false
      },
      save(v) {
        this.$emit('update:canSave', v)
      }
    },
    allGroups() {
      return Object.keys(this.getGroups)
          .map(id => ({
            id: parseInt(id),
            name: this.getGroups[id]
          }))
    }
  },
  created() {

    this.onLoad()
    this.$root.$on('crm::submitBusinessUnit', (this.listeners.submit = () => {
      this.submit()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::submitBusinessUnit', this.listeners.submit)
  },
  methods: {
    ...mapActions('branches', {
      _getBusinessUnit: 'get',
      _updateBusinessUnit: 'update',
      _register: 'register'
    }),
    onLoad() {
      this.loaded = false
      if (this.id === 0 || typeof this.id !== 'number') {
        this.id = 0
        this.businessunit = {
          id: 0,
          group: null,
          cur: 'EUR',
          externalLink: '',
          website: '',
        }
        this.loaded = true
      } else {
        this.loadBusinessUnit()
      }
    },
    updatedHours: function (val) {
      // do whatever you want here
      console.log('updatedHours', val)
    },
    loadBusinessUnit() {
      let $this = this
      $this.loaded = false
      this._getBusinessUnit({ id: $this.id })
          .then((businessunit) => {
            $this.businessunit = businessunit
            //Set page as loaded
            this.loaded = true
          }, (error) => {
            $this.error = error
          })
    },
    createBusinessUnit() {
      let $this = this
      this._register({ branch: this.businessunit })
          .then(
              (id) => $this.$emit('submitted', {
                status: true,
                server: id,
                id
              }),
              (a) => $this.$emit('submitted', {
                status: false,
                server: a
              })
          )
    },
    updateBusinessUnit() {
      let $this = this
      this._updateBusinessUnit({
        id: this.id,
        branch: this.businessunit
      })
          .then(
              (a) => $this.$emit('submitted', {
                status: true,
                server: a
              }),
              (a) => $this.$emit('submitted', {
                status: false,
                server: a
              })
          )
    },
    validate(field) {
      return this.$v.businessunit[field] != null && this.$v.businessunit[field].$error === true
    },
    submit() {
      if (this.id !== 0) {
        return this.updateBusinessUnit()
      }
      return this.createBusinessUnit()

    }

  },

}
</script>


